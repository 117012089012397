.cookieContainer {
  /* place */
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 1rem;
  z-index: 1000;

  /* container styling */
  background-color: #050404;
  padding: 1rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  /* text styling */
  color: #f1f1f1;
  font-size: 1.05rem;
}
