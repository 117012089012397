.mainContainer {
  padding: 2rem 1.5rem;
  padding-top: 6rem;
  margin: 1rem auto;
}
@media screen and (min-width: 768px) {
  .mainContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .mainContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .mainContainer {
    width: 1170px;
  }
}
@media screen and (min-width: 1400px) {
  .mainContainer {
    width: 1370px;
  }
}

.card {
  max-height: 600px;
  /* margin: 0.5rem 0.5rem; */
  overflow: hidden;
  margin: 0.2rem;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .card:hover {
    /* transform: scale(1.1); */
  }
}

@keyframes comeIn {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.xmasonry .xblock {
  width: 100%;
  animation: comeIn ease 0.5s;
  animation-iteration-count: 1;
  transition: left 0.3s ease, top 0.3s ease;
}

.imageSection {
  padding: 3rem 0rem;
}

.title {
  /* center */
  text-align: center;
  border-bottom: #a35e00 3px solid;
  padding-bottom: 0.5rem;
}
