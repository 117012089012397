.languageContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 8rem;
  padding-right: 2rem;
}

.langIcon {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem;
}
