.mainContainer {
  padding: 2rem 1.5rem;
  padding-top: 8rem;
  margin: 1rem auto;
  min-height: 86vh;
}
@media screen and (min-width: 768px) {
  .mainContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .mainContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .mainContainer {
    width: 1170px;
  }
}
@media screen and (min-width: 1400px) {
  .mainContainer {
    width: 1370px;
  }
}

.col {
  padding: 2rem;
}

.searchBar {
  display: block;
  width: 60%;
  /* align center */
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 992px) {
  .searchBar {
    width: 75%;
  }
}
@media screen and (max-width: 768px) {
  .searchBar {
    width: 80%;
  }
}
@media screen and (max-width: 576px) {
  .searchBar {
    width: 100%;
  }
}

.searchForm {
}

.bandCard {
  max-height: 600px;
  margin: 0.5rem 0.5rem;
  overflow: hidden;
  border-color: var(--highlight-yellow-light);
}

.bandImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bandImgOverlay {
  position: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  align-items: end;
}

.bandName {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  background-color: #a35f007a;
  font-family: var(--headline-font);
  text-align: right;
  width: auto;
}

.searchlabel {
  color: #111;
  font-weight: 300;
}

.bandNameLink {
  color: #f1f1f1;
  text-decoration: none;
  word-break: normal;
  right: 0;
}
.bandNameLink:hover {
  text-decoration: underline;
  color: #f1f1f1;
  text-decoration-color: #f1f1f1;
}

@keyframes comeIn {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.xmasonry .xblock {
  width: 100%;
  animation: comeIn ease 0.5s;
  animation-iteration-count: 1;
  transition: left 0.3s ease, top 0.3s ease;
}
