.aboutMainContainer {
  padding-top: 5rem;
}

.aboutPart {
  padding-bottom: 2rem;
  padding-top: 3.5rem;
}

.aboutContent {
  margin: 1rem auto;
  max-width: 85%;
  font-size: 1rem;
}
.aboutText {
  padding: 0;
  text-align: left;
  color: #f1f1f1;
  /* text shadow */
  text-shadow: 0 0 10px black;
}

@media screen and (min-width: 992px) {
  .aboutContent {
    max-width: 70%;
  }
  .aboutText {
    padding: 0 1.5rem;
  }
}
@media screen and (min-width: 1200) {
  .aboutContent {
    max-width: 65%;
  }
}
@media screen and (min-width: 1400px) {
  .aboutContent {
    max-width: 60%;
  }
}
