.checkbox {
  accent-color: #a35e00;
  border-color: #a35e00 !important;
}

.checkbox:checked {
  accent-color: #a35e00;
  background-color: #a35e00 !important;
  border-color: #a35e00 !important;
}

.input {
  background-color: #f1f1f1;
}
