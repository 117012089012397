.aboutImageContainer {
  display: block;
  max-width: 100%;
  max-height: 100vh;
}

.image {
  display: block;
  margin: 3rem auto 0 auto;
  object-fit: scale-down;
  width: auto;
  height: auto;
  max-height: 60vh;

  /* shadow */
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.75);
  /* slightly round borders */
  border-radius: 0.3rem;
}

.descriptionContainer {
  display: block;
  margin: 0.5rem auto;
  text-align: center;
  white-space: pre-wrap;
  max-width: 85%;
  font-size: 0.9rem;
}

@media screen and (max-width: 552px) {
  .image {
    max-width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}
@media screen and (min-width: 553px) {
  .image {
    max-width: 90%;
  }
}
@media screen and (min-width: 670px) {
  .image {
    max-width: 80%;
  }
}
@media screen and (min-width: 992px) {
  .image {
    max-width: 75%;
  }
  .descriptionContainer {
    max-width: 70%;
  }
}
@media screen and (min-width: 1200px) {
  .image {
    max-width: 70%;
  }
}
