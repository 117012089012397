.accordion {
  /* change the accordion variables */
  --bs-accordion-active-bg: #111;
  --bs-accordion-active-color: #f1f1f1;
  --bs-accordion-bg: #111;
  --bs-accordion-color: #f1f1f1;
  --bs-accordion-border-color: #111;
  --bs-accordion-btn-color: #f1f1f1;
  /* remove focus */
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  /* --bs-accordion-btn-focus-color: #f1f1f1; */
}

.accordionItem {
  margin-bottom: 0.7rem;
  border: 1px solid #111;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25);
}

.accordionHeader {
}

.accordionBody {
  background-color: #040404;
}
