.fullImageSection {
  background-color: black;
}
.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #00000050;
}

.contentContainer {
  padding: 2rem 1.5rem;
  margin: 1rem auto;
}
@media screen and (min-width: 576px) {
  .flexContainer {
    height: 650px;
  }
}
@media screen and (min-width: 768px) {
  .contentContainer {
    width: 750px;
  }
  .flexContainer {
    height: 650px;
  }
}
@media screen and (min-width: 992px) {
  .contentContainer {
    padding: 2rem 10rem;
    width: 980px;
  }
  .flexContainer {
    height: 630px;
  }
}
@media screen and (min-width: 1200px) {
  .contentContainer {
    width: 1170px;
  }
  .flexContainer {
    height: 600px;
  }
}
@media screen and (min-width: 1400px) {
  .contentContainer {
    width: 1370px;
  }
  .flexContainer {
    height: 600px;
  }
}

.textContainer {
  position: relative;
  z-index: 9;
  text-align: left;
  color: white;
  opacity: none;
}

.title {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  /* align top */
  margin-top: 0;
  padding-bottom: 0.5rem;
}

.textParagraph {
  /* font-size: 1.5rem; */
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
