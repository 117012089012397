.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%;
  height: 100%; */
  overflow: hidden;
  /* padding: 0 10%; */
  background-color: inherit;
}

@media screen and (max-width: 768px) {
  .main_container {
    padding: 0 5%;
  }
}

.title {
  width: 100%;
  font-size: 2.5rem;
  color: #fff;
  /* align top */
  margin-top: 0;
  padding-bottom: 0.5rem;
  left: 0;
  text-decoration: underline;
}

.buttonContainer {
  padding: 2rem 0;
}
