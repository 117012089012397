.carouselContainer {
  position: relative;
  bottom: 0;
  width: 50%;
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}

.contentContainer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.left-text .carouselContainer {
  margin-left: 2rem;
  float: right;
}
.right-text .carouselContainer {
  margin-right: 2rem;
  float: left;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  .no_border {
    padding: 0;
    margin: 0;
  }
  .contentContainer.top-text {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .textContainer {
    padding: 2rem 1.5rem;
    margin: 1rem auto;
  }
  .contentContainer.bottom-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .left-text .carouselContainer,
  .right-text .carouselContainer,
  .carouselContainer {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .top-text .carouselContainer {
    margin-top: 1.5rem;
  }
  .bottom-text .carouselContainer {
    margin-bottom: 1.5rem;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.eventHighlight {
  text-align: left;
  overflow: hidden;
}
.highlightRow {
  margin: 0 1rem !important ;
}

.bandCounterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.bandCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0 1.5rem;
  border: 3px solid #a35e00;

  font-size: 1.5rem;
  font-weight: 600;
}

.bandCounterText {
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 0.5rem;
}
