.footer {
  background-color: #0c0c0c;
  color: white;
  padding: 30px 0;
  text-align: center;
  font-size: 14px;
}

.span {
  color: #a35e00;
}

.link {
  color: #a35e00;
  text-decoration: none;
}

.link:hover {
  color: #a35e00;
  text-decoration: underline;
}
