/* ========================================================== */
/* ========================================================== */
/* ========================================================== */
/* DoubleButton */

.doubleButton {
  display: inline-block;
  text-decoration: none;
  position: relative;
  margin-top: 40px;
}

.doubleButton .bottom {
  position: absolute;
  left: 7px;
  top: 7px;
  width: 100%;
  height: 100%;
  background-color: #111;
  display: block;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.doubleButton-light .bottom {
  background-color: #252323;
}

.doubleButton .top {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 24px 34px 22px 34px;
  border: 2px solid #a35e00;
}

.doublebutton-light .top {
  border: 2px solid #a35e00;
}

.doubleButton .top .label {
  font-family: sans-serif;
  font-weight: 600;
  color: #f1f1f1;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.doublebutton-light .top .label {
  color: #f1f1f1;
}

.doubleButton:hover .bottom {
  left: 0;
  top: 0;
  background-color: #a35e00;
}

.doubleButton:hover .top .label {
  color: #111;
}

.doubleButton-border {
  position: absolute;
  background-color: #111;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.doubleButton:hover .top .doubleButton-border-left,
.doubleButton:hover .top .doubleButton-border-right {
  height: calc(100% + 2px);
}

.doubleButton:hover .top .doubleButton-border-top,
.doubleButton:hover .top .doubleButton-border-bottom {
  width: calc(100% + 2px);
}

.doubleButton-border-left {
  left: -2px;
  bottom: -2px;
  width: 2px;
  height: 0;
}

.doubleButton-border-top {
  left: -2px;
  top: -2px;
  width: 0;
  height: 2px;
}

.doubleButton-border-right {
  right: -2px;
  top: -2px;
  width: 2px;
  height: 0;
}

.doubleButton-border-bottom {
  right: -2px;
  bottom: -2px;
  width: 0;
  height: 2px;
}

/* ========================================================== */
/* ========================================================== */
/* ========================================================== */
/* Arrow Button */
.arrowButton {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;

  /* border */
  /* border-top: 2.5px solid #a35e00;
  border-top-left-radius: 1.625rem; */
}
.arrowButton.firstStage {
  width: var(--arrow-width);
  height: auto;
}
.arrowButton.firstStage .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #a35e00;
  border-radius: 1.625rem;
}
.arrowButton.firstStage .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.arrowButton.firstStage .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.arrowButton.firstStage .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
.arrowButton.firstStage .arrowButton-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  padding-left: 1rem;
  margin: 0 0 0 1.05rem;
  color: #f1f1f1;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.arrowButton:hover .circle {
  width: 100%;
}
.arrowButton:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
.arrowButton:hover .arrowButton-text {
  color: #fff;
}
