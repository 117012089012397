#rcc-confirm-button {
  /* button styling */
  background-color: #a35e00;
  color: #f1f1f1;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  float: right;
}

#rcc-decline-button {
  /* button styling */
  /* give a grey, deactive looking color */
  background-color: #88858585;
  color: #f1f1f1;
  border: none;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  float: right;
}
