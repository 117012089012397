:root {
  --headline-color: #ff9900;
  --headline-font: "Balthazar-Regular";
  --highlight-yellow: #a35e00;
  --highlight-yellow-light: #ff9900;
  --highlight-yellow-dark: #a35e00;
  --text-color: #f1f1f1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Trebuchet MS", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252323;
  color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Container for the content */
.mainContainer {
}

.dark {
  background-color: #111;
  color: #f1f1f1;
  /* shadow */
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.75);
}

.contentContainer {
  padding: 2rem 1.5rem;
  margin: 1rem auto;
}
@media screen and (min-width: 769px) {
  .contentContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .contentContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .contentContainer {
    width: 1170px;
  }
}
@media screen and (min-width: 1400px) {
  .contentContainer {
    width: 1370px;
  }
}

@font-face {
  font-family: "Balthazar-Regular";
  src: local("Balthazar-Regular"),
    url("./fonts/Balthazar-Regular.ttf") format("truetype");
}
