.mainContainer {
  padding: 0rem 1.5rem;
  /* padding-top: 8rem; */
  margin: 1rem auto;
}
@media screen and (min-width: 768px) {
  .mainContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .mainContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .mainContainer {
    width: 1170px;
  }
}

.titleContainer {
  margin-bottom: 1rem;
}

.descriptionContainer {
  position: relative;
  margin: 3rem 2.5rem;
  /* border: 2px solid #111; */
  /* automatically break lines */
  word-wrap: break-word;

  box-shadow: 0 3px 10px #111;
}
.smallDescriptionContainer {
  /* height */
  max-height: 60vh;
  min-height: 60vh;
  overflow: hidden;
}
.showMoreDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #252323;
  margin-top: 0.5rem;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  justify-content: center;
  /* add top shaddow */
  box-shadow: 0px 0px 40px 60px #252323;
}
.showMoreButton {
  background: none !important;
  border: none !important;
  padding: 0;
  cursor: pointer;
  color: #c17710 !important;
  padding: 0 0.4rem;
}
.showMoreButton:hover {
  /* shadow */
  box-shadow: 0 0 3px 0px #c17710;
}

@media screen and (min-width: 993px) {
  .descriptionContainer {
    padding: 1.5rem 2.5rem;
  }
}
@media screen and (max-width: 992px) {
  .descriptionContainer {
    padding: 2rem 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .descriptionContainer {
    margin: 3rem 1rem;
    padding: 1.5rem 1.5rem;
  }
}
@media screen and (max-width: 425px) {
  .descriptionContainer {
    margin: 3rem 0;
  }
}

.videoContainer {
  padding: 2rem 15px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.wholeDescriptionButtonContainer {
  display: none;
}

@media screen and (max-width: 576px) {
  /* mobile */
  .videoContainer {
    width: 100%;
  }
  /* .descriptionContainer {
    max-height: 600px;
    overflow: hidden;
  } */
}

@media screen and (min-width: 768px) {
  .videoContainer {
    max-width: 75%;
  }
}

/* @media screen and (min-width: 992px) {
  .videoContainer {
  }
}

@media screen and (min-width: 1200px) {
  .videoContainer {
    max-width: 75%;
  }
} */

.cancel_span {
  font-size: 1.3rem;
  font-weight: 700;
  color: #d01616;
  padding-left: 1rem;
}
