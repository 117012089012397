.carouselContainer3D {
  min-height: 300px;
  width: 100%;
  height: 600px;
  /* max-height: 700px; */
  margin: 0 auto;
}

.overlay_parent {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bands_overlay {
  position: absolute;
  /* place this on the bottom and center it */
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.592);
  padding: 2rem 1.5rem;
  margin: 1rem auto;
  border-radius: 0.5rem;

  text-align: center;
}

.mainContainer {
  width: 100%;
  height: 100%;
  padding-top: 0%;
}
@media screen and (min-width: 950px) {
  /* .mainContainer {
    padding: 3rem;
  } */
  .bands_overlay {
    padding: 2rem 1.5rem;
    margin: 1rem auto;
  }
}

.textContainer {
  width: 50%;
  height: 100%;
  padding-top: 1rem;
  margin: 0 auto;
}
@media screen and (max-width: 950px) {
  .textContainer {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .textContainer {
    width: 100%;
  }
}

.bandCounter {
  font-family: var(--headline-font);
  color: var(--headline-color);
  font-size: 3rem;
}
