.mainContainer {
  /* padding: 2rem 1.5rem; */
  padding-top: 6rem;
  /* margin: 1rem auto; */
}
/* @media screen and (min-width: 768px) {
  .mainContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .mainContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .mainContainer {
    width: 1170px;
  }
}
@media screen and (min-width: 1400px) {
  .mainContainer {
    width: 1370px;
  }
} */
