.row {
  width: 100%;
  margin: 0;
  padding: 0;
}

.col {
  padding: 0 0.5rem;
}

.imageCol {
  position: relative;
  overflow: hidden;

  min-height: 250px;
}
@media screen and (min-width: 768px) {
  .imageCol {
    min-height: 315px;
    max-height: 465px;
  }
}
@media screen and (min-width: 992px) {
  .imageCol {
    min-height: 450px;
    max-height: 600px;
  }
}

.imageContainer {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: var(--image-path);
  background-size: cover;

  /* slightly round borders */
  border-radius: 0.3rem;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  background-color: #111;
  padding: 1rem 0.7rem;
  height: 100%;
}
@media screen and (max-width: 991px) {
  .infoContainer {
    margin-top: 1rem;
  }
}

.week {
  display: table;
  width: 100%;
  cursor: default;
}

.week span {
  display: table-cell;
  text-align: center;
  width: 13%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.628);
}

.mo,
.di,
.mi,
.do,
.fr,
.sa,
.so {
  font-weight: 300;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.628);
}

.mo.Mo,
.di.Di,
.mi.Mi,
.do.Do,
.fr.Fr,
.sa.Sa,
.so.So {
  font-size: 2rem;
  color: #a35e00;
  width: 22%;
  border-color: #a35e00;
  font-weight: 600;
}

.dateContainer {
  /* padding-top: 0.2rem; */
}

.start_day {
  font-size: 2rem;
  font-weight: 600;
  color: #f1f1f1;
  letter-spacing: 0px;
}

.start_month_year {
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 0.3rem;
}

.timesTable {
  width: 100%;
  border-style: hidden;
  /* border-collapse: collapse;
  border-spacing: 0; */
  /* border-color: #a35e00; */
  text-align: left;
  margin-top: 0.5rem;
}

.tableRow {
  padding: 0 15px !important;
  border-style: hidden;
}

.timeLabel {
  width: 90px;
}

.timeLabel,
.time {
  color: #f1f1f1;
  padding: 0 !important;
  font-size: 1.2rem;
  font-weight: 300;
}

.bandLinkContainer {
  display: flex;
  width: 100%;
  /* height: 100%; */
  margin-top: 0.5rem;
  color: #f1f1f1;
  word-wrap: break-word;
}

.bandLink {
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  text-decoration: none;
  color: #f1f1f1;
}
.bandLink:hover {
  color: #a35e00;
  text-decoration: underline;
}

.icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem;
  /* center */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.LinkButtonsDiv {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.buttonElement {
  min-width: 100%;
  background-color: #a35e00;

  /* font */
  font-size: 1.2rem;
  font-weight: 400;
  color: #f1f1f1;
  padding: 0.5rem 0;

  /* border */
  border: none;
  border-radius: 4px;

  text-decoration: none;
  /* transition: background-color 0.3s ease-out; */
}

.ticketLink {
  /* remove link */
  text-decoration: none;
}
.disabledLink {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .facebookColf {
    padding-right: 1px;
  }
  .facebookColc {
    padding-left: 1px;
  }
}

.butActive.linkButton:hover {
  background-color: #005f6b;
}
.butActive.facebookButton:hover {
  background-color: #4267b2;
}
.butActive.calendarButton:hover {
  background-color: #ff3b2f;
}

.butCancelled,
.butCancelled:hover {
  /* font */
  font-size: 1.2rem;
  font-weight: 400;
  color: #f1f1f1;
  padding: 0.5rem 0;

  /* deactivated grey */
  /* background-color: #333; */
  /* little opacity */
  opacity: 0.5;

  /* border */
  border: none;
  border-radius: 4px;
  /* no transition on click */
  transition: none;
  pointer-events: none;
  cursor: default;
}

/* .cancelled {
  text-decoration: line-through;
  white-space: nowrap !important;
  text-decoration-color: #7f1010;
  text-decoration-thickness: 3px;
} */
