.navbar {
  padding-bottom: 0px;
  padding-right: 1rem;
  overflow: hidden;
  /* center content */
  display: flex;
  justify-content: right;
  align-items: right;
}

.navbarToggler {
  background-color: #f1f1f1 !important;
}

.navbarNoBackground {
  padding-top: 20px;
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.317); */
  /* TRANSITION */
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.5s linear;
}

.navbarBackground {
  padding-top: 0.4rem;
  background-color: #0c0c0c;
  /* TRANSITION */
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
  /* slight transparent */
  background-color: rgba(0, 0, 0, 0.851);
}

.navbarBackground:hover {
  background-color: #0c0c0c;
}

.nav {
  /* align items right */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navItem {
  /* read from browser inspect */
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navLink {
  /* text coloring */
  color: #f1f1f1 !important;
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  font-size: larger;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem;
}
.navLink::after {
  /* underline */
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #a35e00;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}
.navLink:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.active::after {
  /* underline */
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--highlight-yellow-light);
  /* transform-origin: bottom left;
    transition: transform 0.25s ease-out; */
  transform: scaleX(1);
}

@media screen and (max-width: 767px) {
  .navLink {
    margin-bottom: 0.6rem;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    padding-left: 0.6rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .brand {
    /* hide the brand */
    /* display: none; */
  }
  .navbar {
  }
}
