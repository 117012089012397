/* CSS */
.button_1 {
  display: inline-block;
  width: 10%;
  background: transparent;
  padding: 0.5rem 0.5rem;
  margin: 1rem 1rem;
  transition: all 0.5s ease;
  color: #f4efef;
  font-size: 1rem;
  font-weight: 400;
  outline: none;
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  position: relative;
  text-decoration: none;
}

.button_1,
.button_1:before {
  border-radius: 255px 35px 225px 45px/25px 255px 35px 255px;
}

.button_1:before {
  display: block;
  width: 10%;
  /*transition: all 0.5s ease;*/
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  top: 8px;
  z-index: -1;
}
.button_2:focus {
  outline: 0;
}

.button_2:after {
  content: "";
  position: absolute;
  border: 1px solid #f6fbf8;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button_2:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button_2 {
    padding: 0.75rem 3rem;
    font-size: 1.25rem;
  }
}

/* CSS */
.button_3 {
  appearance: none;
  background-color: #000000;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 30%;
  will-change: transform;
  border: 0 solid #f6f0f0;
  text-decoration: none #f7f4f4 solid;
  text-decoration-thickness: auto;
}

.button_3:disabled {
  pointer-events: none;
}

.button_3:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button_3:active {
  box-shadow: none;
  transform: translateY(0);
}
