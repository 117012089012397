.mainMapContainer {
  width: 100%;
  height: 100%;
  /* center content */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

/* .mainContainer {
  flex-direction: column;
} */
.leaflet-container {
  width: 70%;
  height: 50vh;
}
@media screen and (max-width: 768px) {
  .leaflet-container {
    width: 70%;
    height: 50vh;
  }
}
@media screen and (max-width: 576px) {
  .leaflet-container {
    width: 100%;
    height: 50vh;
  }
}
@media screen and (max-width: 480px) {
  .leaflet-container {
    width: 100%;
    height: 50vh;
  }
}

.full_map .leaflet-container {
  width: 100%;
  height: 100%;
  min-height: 40vh;
}
