.mainContainer {
}

.headerContainer {
  padding: 2rem 1.5rem;
  padding-top: 8rem;
  margin: 1rem auto;
}

.mail_a {
  color: #f1f1f1;
}
.mail_a:hover {
  color: #a35e00;
}

.icon {
  margin: 0 0.5rem;
}

/* .dark {
  background-color: #111;
  color: #f1f1f1;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.75);
}

.contentContainer {
  padding: 2rem 1.5rem;
  padding-top: 8rem;
  margin: 1rem auto;
}
@media screen and (min-width: 768px) {
  .contentContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .contentContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .contentContainer {
    width: 1170px;
  }
} */

.checkbox:checked {
  accent-color: #a35e00;
  background-color: #a35e00 !important;
  border-color: #a35e00 !important;
}

@media screen and (max-width: 767px) {
  .faqContentContainer {
    width: 100%;
  }
}
