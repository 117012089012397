.navLink {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  cursor: default;
  margin: 0.5rem 0;

  border: 1px solid #111;
  border-radius: 1rem;
  overflow: hidden;
  transition: 0.5s;
}

.row:hover {
  /* small scale */
  transform: scale(1.025);
  transition: 0.5s;
}

.row .text_container {
  background-color: #111;
}
/* .row:hover .text_container {
    background-color: #a35e00;
} */
.row .date_container {
  background-color: #a35e00;
}
/* .row:hover .date_container {
    background-color: #111;
} */

.colCancelled {
  opacity: 0.3;
}

.image_container {
  padding: 0;
  margin: 0;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  background-position: 50%;
  background-size: cover;
  display: flex;
}

/* @media screen and (max-width: 768px) {
  .image_container {
    display: none;
  }
} */

.text_container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  margin: 0;
}

.title {
  font-family: var(--headline-font);
  color: var(--text-color);
  font-size: 1.7rem;
  /* align top */
  margin-top: 0;
  padding-bottom: 0.5rem;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  color: #f1f1f1;
  max-width: 100%;
  min-height: 75px;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 768px) {
  .date_container {
    padding: 0;
    margin: 0;
    /* align center */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s;
  }
  .date_container_mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .date_container {
    display: none;
  }
  .date_container_mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0.8rem 0;
    /* shadow */
    box-shadow: 0 0 0.5rem 0.5rem #111;
    background-color: #a35e00;
  }
}

.time {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
  padding-top: 0.8rem;
}

.day {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.month {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
}

.date_mobile {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
}

.cancelled {
  text-decoration: line-through;
  white-space: nowrap !important;
}

.moreButtonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
}

.more_button {
  color: #a35e00;
  text-decoration: none;
}
.more_button:hover {
  color: #a35e00;
  text-decoration: underline;
}

.cancel_span {
  color: rgb(208, 22, 22);
  /* style cancelled text */
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
}
.wrapper {
  position: relative;
}
