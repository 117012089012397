.carouselImage {
  width: 100%;
  max-height: inherit;
  object-fit: cover;
  object-fit: contain;
  border-radius: 0.3rem;
}

.carousel {
  color: #a35e00;
  /* shadow */
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.75);
  /* slightly round borders */
  border-radius: 0.3rem;
  max-height: inherit;
}

@media (max-width: 900px) {
  .carousel {
    width: auto;
    height: auto;
  }
}
