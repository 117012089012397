.listItem {
  position: relative;
  padding: 0.3rem 0.3rem;
  margin: 0.2rem 0;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  border-color: var(--highlight-yellow);
  text-align: center;
}

.listItem:hover {
  cursor: pointer;
  /* border-color: #a35e00;
  background-color: #252323; */
  /* text-align: center; */
}

.listItem:hover .itemTitle {
  color: var(--highlight-yellow);
}
.listItem:hover .itemDate {
  color: var(--text-color);
}

.itemDate {
  padding-bottom: 0;
  margin-bottom: 0;
  color: var(--highlight-yellow);
}

.itemTitle {
  font-size: 1.22rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: var(--text-color);
  font-family: var(--headline-font);
}
