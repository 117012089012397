.mainContainer {
  padding: 2rem 1.5rem;
  padding-top: 8rem;
  margin: 1rem auto;
}
@media screen and (min-width: 768px) {
  .mainContainer {
    width: 750px;
  }
}
@media screen and (min-width: 992px) {
  .mainContainer {
    width: 980px;
  }
}
@media screen and (min-width: 1200px) {
  .mainContainer {
    width: 1170px;
  }
}

.titleContainer {
  /* underline */
  border-bottom: 2px solid #a35e00;
  margin-bottom: 2rem;
  text-align: center;
}

.contentContainer {
  padding: 0rem 2rem;
  line-height: 1.5;
}

.subtitle {
  margin-bottom: 1rem;
}

.p_section {
  line-height: 1;
  margin-bottom: 2rem;
}

.a {
  word-break: break-all;
  text-decoration: none;
  color: var(--highlight-yellow-light);
}
.a:hover {
  color: var(--highlight-yellow-light);
  text-decoration: underline;
}

.email_link {
  padding-left: 3px;
  text-decoration: none;
  color: var(--highlight-yellow-light);
}
.email_link:hover {
  color: var(--highlight-yellow-light);
  text-decoration: underline;
}

.pText {
  line-height: normal;
}
