.quote {
  background-color: #a35f00b6;
  padding: 1.5rem;
  position: relative;

  /* smooth border */
  border-radius: 0.5rem;
}

.quoteText {
  font-size: 1.2rem;
  text-align: center;
}

.quoteAuthor {
  font-size: 1.5rem;
  font-family: "Bradley Hand";
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  margin-top: 1rem;
  text-align: center;
}

.mainDiv {
  background-color: #111;
  padding: 2rem 5rem;
  font-family: Arial;
  color: #f1f1f1;
  /* round border */
  border-radius: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem !important;

  border: 1px solid var(--highlight-yellow-light);
}
.p2 {
  font-size: 1rem;
  text-align: center;
}
