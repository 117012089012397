.mainContainer {
  overflow: hidden;
}

.fullWidthImageContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.mainImage {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  object-fit: cover;
  overflow: hidden;
  background-position: 50%;
  background-size: cover;
  display: flex;
}

.mainImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  align-items: end;
}

.mainImageText {
  /* position bottom left */
  position: absolute;
  bottom: 4rem;
  left: 10%;
  /* padding */
  /* padding: 1rem; */
  /* text shadow */
  text-shadow: 0 0 10px black;
  font-family: var(--headline-font);
}

.mainImageTitle {
  font-size: 6rem;
  font-weight: 700;
  color: var(--headline-color);
  /* align top */
  margin-top: 0;
  margin-bottom: -0.5rem;
  font-family: var(--headline-font);
}
.mainImageSubtitle {
  font-size: 2.5rem;
  font-weight: 400;
  color: #f1f1f1;
  /* align top */
  margin-top: 0;
  padding-bottom: 0.5rem;
}
